import React from 'react'
import './navbar.css'
import { BsSun, BsMoon } from 'react-icons/bs'
const Nav = ({ setLightMode, lightMode }) => {
    const [navbarScroll, setNavbarScroll] = React.useState(false);
    const changeBackground = () => {
        if (window.scrollY >= 66) {
            setNavbarScroll(true)
        } else {
            setNavbarScroll(false)
        }
    }
    React.useEffect(() => {
        changeBackground()
        // adding the event when scroll change Logo
        window.addEventListener("scroll", changeBackground)
    })
    return (
        <>
            <div className={navbarScroll ? 'nav_container ' : 'nav_container active'}>
                <h1>Hamza Saleem</h1>
                <button onClick={() => {
                    setLightMode(pre => !pre)
                }}>
                    {
                        lightMode ? <BsMoon /> : <BsSun />
                    }
                </button>
            </div>
        </>
    )
}

export default Nav
