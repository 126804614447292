import React from 'react'
import './about.css'
import ME from '../../Assets/me-about.jpg'
import { FaAward } from 'react-icons/fa'
import { FiUsers } from 'react-icons/fi'
import { VscFolderLibrary } from 'react-icons/vsc'

const About = () => {
    return (
        <section id='about'>
            <h5>Get To Know</h5>
            <h2>About Me</h2>

            <div className="container about__container">
                <div className="about__me">
                    <div className="about__me-image">
                        <img src={ME} alt="About Image" />
                    </div>
                </div>

                <div className="about__content">
                    <div className="about__cards">
                        <article className="about__card">
                            <FaAward className='about__icon' />
                            <h5>Experience</h5>
                            <small>1+ Year</small>
                        </article>

                        <article className="about__card">
                            <FiUsers className='about__icon' />
                            <h5>Projects</h5>
                            <small>10+ Completed</small>
                        </article>

                        <article className="about__card">
                            <VscFolderLibrary className='about__icon' />
                            <h5>Clients</h5>
                            <small>Not Yet</small>
                        </article>
                    </div>
                    <p>
                        “Young professional with a passion for software development, have done my bachelor's from Sir Syed University of Engineering and Technology in Software Engineering. I am an honored Full Stack Web Developer. I am dedicated to the effort for Web Development and plan to make myself, my experiences and my education as a useful goal in my career. I am dedicated to providing efficient, Prudential decisions to help solve complex issues. "
                    </p>

                    <a href="#contact" className='btn btn-primary'>Let's Talk</a>
                </div>
            </div>
        </section>
    )
}

export default About
