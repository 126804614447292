import React from 'react'
import Navbar from './Components/Navbar/Navbar'
import Header from './Components/Header/Header'
import Nav from './Components/Nav/Nav'
import About from './Components/About/About'
import Experience from './Components/Experience/Experience'
import Services from './Components/Services/Services'
import Portfolio from './Components/Portfolio/Portfolio'
// import Testimonials from './Components/Testimonials/Testimonials'
import Contact from './Components/Contact/Contact'
import Footer from './Components/Footer/Footer'
import PreLoader from './Components/PreLoader/PreLoader'

const App = () => {
    const [lightMode, setLightMode] = React.useState(false);
    const [modal, setModal] = React.useState(false);

    return (
        <>
            <PreLoader />

            <div className={lightMode ? 'light_mode' : ''}>
                <Navbar lightMode={lightMode} setLightMode={setLightMode} />
                <Header />
                <Nav modal={modal} />
                <About />
                <Experience />
                <Services modal={modal}
                    setModal={setModal} />
                <Portfolio />
                {/* <Testimonials /> */}
                <Contact />
                <Footer />
            </div>
        </>
    )
}

export default App;
