import React from 'react'
import './portfolio.css'
import IMG1 from '../../Assets/portfolio1.png'
import IMG2 from '../../Assets/portfolio2.jpg'
import IMG3 from '../../Assets/portfolio3.jpg'
import IMG4 from '../../Assets/portfolio4.jpg'
import IMG5 from '../../Assets/portfolio5.png'
import IMG6 from '../../Assets/portfolio6.jpg'

const data = [
    {
        id: 1,
        image: IMG1,
        title: 'The Fit Club',
        description: '(Functional React JS Website)',
        github: 'https://github.com/Hamza-Project/the-fit-club.git',
        demo: 'https://thefitclubb.pages.dev/'
    },
    {
        id: 2,
        image: IMG2,
        title: 'ASH Softwares',
        description: '(HTML CSS Website)',
        github: 'https://github.com/Hamza-Project/ASH-Softwares',
        demo: 'https://ashsoftwares.web.app'
    },
    {
        id: 3,
        image: IMG3,
        title: 'Bank Management System',
        description: '(Java and MySQL)',
        github: 'https://github.com/Hamza-Project/BankManagement',
        demo: ''
    },

    // {
    //     id: 4,
    //     image: IMG4,
    //     title: 'Working on it ...',
    //     github: 'https://github.com/Hamza-Project',
    //     demo: ''
    // },
    // {
    //     id: 5,
    //     image: IMG5,
    //     title: 'Working on it ...',
    //     github: 'https://github.com/Hamza-Project',
    //     demo: ''
    // },
    // {
    //     id: 6,
    //     image: IMG6,
    //     title: 'Working on it ...',
    //     github: 'https://github.com/Hamza-Project',
    //     demo: ''
    // }
]



const Portfolio = () => {
    return (
        <section id='portfolio'>
            <h5>My Recent Work</h5>
            <h2>Portfolio</h2>

            <div className='container portfolio__container'>
                {
                    data.map(({ id, image, title, description, github, demo }) => {
                        return (
                            <article key={id} className='portfolio__item'>
                                <div className="portfolio__item-image">
                                    <img src={image} alt={title} />
                                </div>
                                <h3>{title}</h3>
                                <small className='portfolio__item__desc'>{description}</small>
                                <div className="portfolio__item-cta">
                                    <a href={github} className='btn' target='_blank' >Github</a>
                                    <a style={{ display: id === 3 ? "none" : "" }} href={demo} className='btn btn-primary' target='_blank' rel="noreferrer">Live Demo</a>
                                </div>
                            </article>
                        )
                    })
                }
            </div>
        </section >
    )
}

export default Portfolio
