import React from 'react'
import './services.css'
import { BiCheck } from 'react-icons/bi'
import { AiOutlineClose } from 'react-icons/ai'


const ServicesModal = ({ toggleModal }) => {
    return (
        <section>
            <div>
                <div className='container services__container'>
                    <article className="service">
                        <span onClick={toggleModal}><AiOutlineClose className='services__close-icon' /> </span>
                        <div className="service__head">
                            <h3>Web Development</h3>
                            <small>Providing quality work to clients and Companies</small>
                        </div>

                        <ul className='service__list'>
                            <li>
                                <BiCheck className='service__list-icon' />
                                <p>I develop the user interface.</p>
                            </li>

                            <li>
                                <BiCheck className='service__list-icon' />
                                <p>Web page development.</p>
                            </li>

                            <li>
                                <BiCheck className='service__list-icon' />
                                <p>I develop the Front-end of the Web App.</p>
                            </li>

                            <li>
                                <BiCheck className='service__list-icon' />
                                <p>I develop the Full Stack Web App.</p>
                            </li>
                        </ul>
                    </article>
                </div>
            </div>
        </section>
    )
}

export default ServicesModal
