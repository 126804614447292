import React, { useEffect } from 'react'
import './preLoader.css'
import { preLoaderAnim } from '../../Animation';

const PreLoader = () => {
    useEffect(() => {
        preLoaderAnim()
    }, []);

    return (
        <div className='preloader'>
            <div className="texts-container">
                <span>WELCOME,</span>
                <span>PLEASE</span>
                <span>WAIT!!!</span>
            </div>
        </div>
    )
}

export default PreLoader
