import React from 'react'
import { BsLinkedin } from 'react-icons/bs'
import { FaGithub } from 'react-icons/fa'
import { BsFacebook } from 'react-icons/bs'
import { BsInstagram } from 'react-icons/bs'
import { SiFiverr } from 'react-icons/si'

const HeaderSocials = () => {
    return (
        <div className='header__socials'>
            <a href="https://www.linkedin.com/in/hamza-khan-41722a235" target="_blank"><BsLinkedin /></a>
            <a href="https://github.com/Hamza-Project" target="_blank"><FaGithub /></a>
            <a href="https://www.fiverr.com/hamzasaleem323?public_mode=true" target="_blank"><SiFiverr /></a>
            <a href="https://www.facebook.com/profile.php?id=100034687651349" target="_blank"><BsFacebook /></a>
            <a href="https://www.instagram.com/hamzay_18" target="_blank"><BsInstagram /></a>

        </div>
    )
}

export default HeaderSocials
