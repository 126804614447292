import React from 'react'
import './footer.css'
import { FaFacebookF } from 'react-icons/fa'
import { FiInstagram } from 'react-icons/fi'
import { IoLogoTwitter } from 'react-icons/io'
import { SiFiverr } from 'react-icons/si'
import { SiFreelancer } from 'react-icons/si'

const Footer = () => {
    return (
        <footer>
            <a href="#" className='footer__logo'>Muhammad Hamza Saleem</a>

            <ul className='permalinks'>
                <li><a href="#">Home</a></li>
                <li><a href="#about">About</a></li>
                <li><a href="#experience">Experience</a></li>
                <li><a href="#services">Services</a></li>
                <li><a href="#portfolio">Portfolio</a></li>
                {/* <li><a href="#testimonials">Testimonials</a></li> */}
                <li><a href="#contact">Contact</a></li>
            </ul>

            <div className="footer__socials">
                <a href="https://www.facebook.com/profile.php?id=100034687651349" target='_blank'><FaFacebookF /></a>
                <a href="https://www.instagram.com/hamzay_18" target='_blank'><FiInstagram /></a>
                <a href="https://twitter.com/Hamzasaleem323" target='_blank'><IoLogoTwitter /></a>
                <a href="https://www.fiverr.com/hamzasaleem323?public_mode=true" target='_blank'><SiFiverr /></a>
                <a href="https://www.freelancer.com/u/hamzasaleem3233" target='_blank'><SiFreelancer /></a>
            </div>

            <div className="footer__copyright">
                <small>&copy; Hamza Saleem. All rights reserved.</small>
            </div>
        </footer>
    )
}

export default Footer
