import React from 'react'
import './contact.css'
import { MdOutlineEmail } from 'react-icons/md'
import { BsWhatsapp } from 'react-icons/bs'
import { RiMessengerLine } from 'react-icons/ri'
import { useRef } from 'react';
import emailjs from 'emailjs-com'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Contact = () => {

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_mcklb6e', 'template_p7iqngi', form.current, 'e-NlWz3MlXj6B6d9J')
        e.target.reset()
        showAlert()
    };

    const showAlert = () => {
        toast.success("Message Successfully Send", {
            position: "bottom-center",
            theme: "dark",
            autoClose: 3000
        });
    }

    return (
        <section id='contact'>
            <h5>Get In Touch</h5>
            <h2>Contact Me</h2>

            <div className="container contact__container">
                <div className="contact__options">
                    <article className='contact__option'>
                        <MdOutlineEmail className='contact__option-icon' />
                        <h4>Email</h4>
                        <h5>hamzasaleem3233@gmail.com</h5>
                        <a href="mailto:hamzasaleem3233@gmail.com" target="_blank">Send Message</a>
                    </article>

                    <article className='contact__option'>
                        <BsWhatsapp className='contact__option-icon' />
                        <h4>WhatsApp</h4>
                        <h5>+92 318 1073107</h5>
                        <a href="https://api.whatsapp.com/send?phone=+923181073107&text=Hello,%20more%20information!" target="_blank">Send Message</a>
                    </article>

                    <article className='contact__option'>
                        <RiMessengerLine className='contact__option-icon' />
                        <h4>Messenger</h4>
                        <h5>Hamza Khan</h5>
                        <a href="https://www.messenger.com" target="_blank">Send Message</a>
                    </article>
                </div>
                {/* END OF CONTACT OPTIONS  */}

                <form ref={form} onSubmit={sendEmail}>
                    <label className='input'>
                        <input className='input__field' type="text" name='name' placeholder='Insert your full name' required />
                        <span className='input__label'>Name</span>
                    </label>
                    <label className='input'>
                        <input className='input__field' type="email" name='email' placeholder='Insert your email' required />
                        <span className='input__label'>Email</span>
                    </label>
                    <label className='input'>
                        <textarea className='input__field' name="message" rows="7" placeholder='Write your message' ></textarea>
                        <span className='input__label'>Message</span>
                    </label>
                    <button type='submit' className='btn btn-primary'>Send Message</button>

                </form>
            </div>
            <ToastContainer />
        </section>
    )
}

export default Contact
