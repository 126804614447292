import React from 'react'
import './services.css'
import { useState } from 'react'
import ServicesModal from './ServicesModal'
import { AiOutlineArrowRight } from 'react-icons/ai'

const Services = ({ modal, setModal }) => {


    const toggleModal = () => {
        setModal(!modal)
    }

    if (modal) {
        document.body.classList.add('active_modal')
    } else {
        document.body.classList.remove('active_modal')
    }

    return (
        <section id='services'>
            <h5>What I Offer</h5>
            <h2>Services</h2>

            <div className="container services__container-main">
                <article className='service-main'>
                    <div className='service__head-main'>
                        <h3>UI/UX Designer</h3>
                        <div>
                            <a href='#Modal' onClick={toggleModal} className='service__btn-main'>See more <span style={{ display: modal ? "none" : "" }}> <AiOutlineArrowRight className='service__head-icon-main' /></span></a>
                        </div>

                        <div className={modal ? "services_overlay active" : "services_overlay"}>
                            <ServicesModal toggleModal={toggleModal} />
                        </div>

                    </div>
                </article>

                <article className='service-main'>
                    <div className='service__head-main'>
                        <h3>Frontend Developer</h3>
                        <div>
                            <a href='#Modal' onClick={toggleModal} className='service__btn-main'>See more <span style={{ display: modal ? "none" : "" }}> <AiOutlineArrowRight className='service__head-icon-main' /> </span></a>
                        </div>
                    </div>
                </article>

                <article className='service-main'>
                    <div className='service__head-main'>
                        <h3>Full Stack Developer</h3>
                        <div>
                            <a href='#Modal' onClick={toggleModal} className='service__btn-main'>See more <span style={{ display: modal ? "none" : "" }}> <AiOutlineArrowRight className='service__head-icon-main' /></span></a>
                        </div>
                    </div>
                </article>
            </div>
        </section>
    )
}

export default Services
